import React from 'react';
import {QueryClient, QueryClientProvider} from "react-query";
import "./app.less"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onError: (error) => {
        console.error('An error occurred:', error);
      },
    },
  },
})

export function rootContainer(container: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined, args: any) {
  return React.createElement(QueryClientProvider, {client: queryClient,}, container);
}