// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/dev","parentId":"@@/global-layout","id":"1"},"2":{"path":"/","parentId":"@@/global-layout","id":"2"},"3":{"path":"/index","parentId":"@@/global-layout","id":"3"},"4":{"path":"/category","parentId":"@@/global-layout","id":"4"},"5":{"path":"/search","parentId":"@@/global-layout","id":"5"},"6":{"path":"/course","parentId":"@@/global-layout","id":"6"},"7":{"path":"list","parentId":"6","id":"7"},"8":{"path":"info","parentId":"6","id":"8"},"9":{"path":"baoMing","parentId":"6","id":"9"},"10":{"path":"result/page","parentId":"6","id":"10"},"11":{"path":"baoMing/contract","parentId":"6","id":"11"},"12":{"path":"/login","parentId":"@@/global-layout","id":"12"},"13":{"path":"/user","parentId":"@@/global-layout","id":"13"},"14":{"path":"index","parentId":"13","id":"14"},"15":{"path":"baoMing","parentId":"13","id":"15"},"16":{"path":"info","parentId":"15","id":"16"},"17":{"path":"list","parentId":"15","id":"17"},"18":{"path":"audit","parentId":"15","id":"18"},"19":{"path":"salesCenter","parentId":"13","id":"19"},"20":{"path":"index","parentId":"19","id":"20"},"21":{"path":"poster","parentId":"19","id":"21"},"22":{"path":"simpleCourse","parentId":"19","id":"22"},"23":{"path":"statistics","parentId":"19","id":"23"},"24":{"path":"/*","parentId":"@@/global-layout","id":"24"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "p__dev" */'@/pages/dev.tsx')),
'2': React.lazy(() => import(/* webpackChunkName: "p__index" */'@/pages/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__index" */'@/pages/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__category__index" */'@/pages/category/index.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__index__search" */'@/pages/index/search.tsx')),
'6': React.lazy(() => import( './EmptyRoute')),
'7': React.lazy(() => import(/* webpackChunkName: "p__course__list__page" */'@/pages/course/list/page.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__course__info__page" */'@/pages/course/info/page.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__course__baoMing__page" */'@/pages/course/baoMing/page.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__course__result__page" */'@/pages/course/result/page.tsx')),
'11': React.lazy(() => import(/* webpackChunkName: "p__course__baoMing__contract" */'@/pages/course/baoMing/contract.tsx')),
'12': React.lazy(() => import(/* webpackChunkName: "p__login__page" */'@/pages/login/page.tsx')),
'13': React.lazy(() => import( './EmptyRoute')),
'14': React.lazy(() => import(/* webpackChunkName: "p__user__index__page" */'@/pages/user/index/page.tsx')),
'15': React.lazy(() => import( './EmptyRoute')),
'16': React.lazy(() => import(/* webpackChunkName: "p__user__baoMing__info__page" */'@/pages/user/baoMing/info/page.tsx')),
'17': React.lazy(() => import(/* webpackChunkName: "p__user__baoMing__list__page" */'@/pages/user/baoMing/list/page.tsx')),
'18': React.lazy(() => import(/* webpackChunkName: "p__user__baoMing__audit__page" */'@/pages/user/baoMing/audit/page.tsx')),
'19': React.lazy(() => import( './EmptyRoute')),
'20': React.lazy(() => import(/* webpackChunkName: "p__user__salesCenter__index__page" */'@/pages/user/salesCenter/index/page.tsx')),
'21': React.lazy(() => import(/* webpackChunkName: "p__user__salesCenter__poster__page" */'@/pages/user/salesCenter/poster/page.tsx')),
'22': React.lazy(() => import(/* webpackChunkName: "p__user__salesCenter__poster__SimpleCourseList" */'@/pages/user/salesCenter/poster/SimpleCourseList.tsx')),
'23': React.lazy(() => import(/* webpackChunkName: "p__user__salesCenter__statistics__page" */'@/pages/user/salesCenter/statistics/page.tsx')),
'24': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'K:/work/北极星公考H5/umiapp/src/layouts/index.tsx')),
},
  };
}
